import { Tooltip } from "native-base";
import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { useSelector } from "react-redux";
import LogoContactImage from '../../Assets/Images/splashlogo.png';
import AppColor from "../../Constants/Color";
import AppFonts from "../../Constants/Fonts";
import useResponsiveLayout from "../../Middleware/useResponsiveLayout";
import { providers } from "../../Screens/IntegrationProvider";
import ConsentStatusIndicator from "../ConsentStatusIndicator";

const Contact = ({ item, onPress, onCall, onMessage, onDelete, onReconcile, columns, onOptOut }) => {
    const affiliate = useSelector((state) => state.User)?.profile?.affiliate;
    const hasVoiceAddon = useSelector((state) => state.User)?.profile?.tenant?.voice;
    const { isWebCompact } = useResponsiveLayout();
    let phone_numnber_message = 'No Messaging Number'
    let phone_numnber_color = AppColor.danger
    if (item.phone1 !== "") {
        phone_numnber_message = '(' + item.phone1.substring(0, 3) + ') ' + item.phone1.substring(3, 6) + '-' + item.phone1.substring(6, 10);
        phone_numnber_color = AppColor.Dark
    }

    let status_message = ''
    let status_color = AppColor.BlackColor
    if (item.spam) {
        status_message = 'Spam'
        status_color = AppColor.danger
    }
    if (affiliate?.consentEnabled && item.consented) {
        status_color = 'green'
        status_message = 'Consented'
    }
    if (item.stopped) {
        status_message = 'Opt-Out'
        status_color = AppColor.danger
    }

    let image = LogoContactImage;
    let provider = Object.values(providers).find((i) => i.name == item.source);
    if (provider) {
        image = provider.smallImageUrlPath;
    }

    const accountId = item?.accountId || null;
    const consentStatus = item?.stopped ? 'stopped' : item?.consented ? 'consented' : null;

    const columnsArray = Array.isArray(columns) ? columns : [];

    const width = 100 / columnsArray.length;
    const widthPercentage = `${width}%`;

    const showFirstName = columnsArray.includes('fname');
    const showLastName = columnsArray.includes('lname');
    const showEmail = columnsArray.includes('email');
    const showPhone = columnsArray.includes('phone');
    const showStatus = columnsArray.includes('status');
    const showActions = columnsArray.includes('actions');

    return (
        <>
            <TouchableOpacity
                accessible={true}
                accessibilityRole='button'
                onPress={onPress}
                key={item.id}
                style={styles.container}>
                <>
                {
                    isWebCompact &&
                    <View
                    style={[{ width: '50%',alignItems:'center',flexDirection:'row' },]}>
                    <Image source={image} style={styles.logo} />

                    <View>
                        <Text
                            style={[styles.firstNameText, {fontFamily:AppFonts.Roboto_Bold}]}>
                            {item.fname} {item.lname}
                        </Text>
                        <Text 
                        style={styles.firstNameText}>
                            {phone_numnber_message}
                        </Text>

                        {accountId &&
                            <View style={{ flexDirection: 'row' }}>
                                <Icon size={17} name='building-o' type='font-awesome' />
                                <Text style={[styles.columnText, { marginLeft: 2 }]}>{accountId}</Text>
                            </View>
                        }
                    </View>
                </View>
                }
                </>
                

                <>
                    {!isWebCompact &&
                        <>
                            {showFirstName &&
                                <View
                                    style={[{ width: widthPercentage }, styles.firstNameContainer]}>
                                    <Image source={image} style={styles.logo} />

                                    <View>
                                        <Text
                                            style={styles.firstNameText}>
                                            {item.fname}
                                        </Text>
                                        {accountId &&
                                            <View style={{ flexDirection: 'row' }}>
                                                <Icon size={17} name='building-o' type='font-awesome' />
                                                <Text style={[styles.columnText, { marginLeft: 2 }]}>{accountId}</Text>
                                            </View>
                                        }
                                    </View>
                                </View>}
                            {
                                showLastName &&
                                <View
                                    style={[{ width: widthPercentage }, styles.column]}>
                                    <Text
                                        style={styles.columnText}>
                                        {item.lname}
                                    </Text>
                                </View>}
                            {showEmail &&
                                <View style={[{ width: widthPercentage }, styles.column]}>
                                    <Text
                                        style={styles.columnText}>
                                        {item.email_address}
                                    </Text>
                                </View>
                            }
                            {showPhone &&
                                <View style={[{ width: widthPercentage }, styles.column]}>
                                    <Text
                                        style={[
                                            styles.columnText,
                                            { color: phone_numnber_color, }
                                        ]}>
                                        {phone_numnber_message}
                                    </Text>
                                </View>
                            }
                            {showStatus &&
                                <View style={[{ width: widthPercentage }, styles.column, styles.statusColumn]}>
                                    <ConsentStatusIndicator status={consentStatus} size={22} />
                                    <Text
                                        style={
                                            [
                                                styles.columnText,
                                                { color: status_color }
                                            ]}>
                                        {status_message}
                                    </Text>

                                </View>
                            }
                        </>
                    }
                </>

                {showActions &&
                    <View
                        style={[{ width: isWebCompact ? '50%' : widthPercentage }, styles.column, styles.actionsColumn]}>
                        {
                            hasVoiceAddon &&
                            <Tooltip
                                label="Call"
                                openDelay={500}
                                placement="top"
                                hasArrow={true}>
                                <View
                                    style={styles.actionIconContainer}>
                                    <Icon
                                        accessibilityLabel='phone'
                                        accessibilityHint='Call contact.'
                                        onPress={onCall}
                                        name={'call-outline'}
                                        type={'ionicon'}
                                        style={styles.actionIcon}
                                    />
                                </View>
                            </Tooltip>
                        }

                        <Tooltip
                            label="Message"
                            openDelay={500}
                            placement="top"
                            hasArrow={true}>
                            <View
                                style={styles.actionIconContainer}>
                                <Icon
                                    accessibilityLabel='Message'
                                    accessibilityHint='Message contact.'
                                    accessibilityRole='button'
                                    onPress={onMessage}
                                    style={styles.actionIcon}
                                    name={'message1'}
                                    type={'antdesign'}
                                />
                            </View>
                        </Tooltip>
                        <Tooltip

                            label="Delete"
                            openDelay={500}
                            placement="top"
                            hasArrow={true}>
                            <View
                                style={styles.actionIconContainer}>
                                <TouchableOpacity
                                    accessibilityLabel='Trash'
                                    accessibilityHint='Delete Contact.'
                                    accessibilityRole='button'

                                    onPress={onDelete}>
                                    <EvilIcons name="trash" size={30} />
                                </TouchableOpacity>
                            </View>
                        </Tooltip>
                        <Tooltip
                            label="Reconnect to CRM"
                            openDelay={500}
                            placement="top"
                            hasArrow={true}>
                            <View
                                style={styles.actionIconContainer}>
                                <Icon
                                    accessibilityLabel='Reconcile.'
                                    accessibilityHint='Reconcile Contact.'
                                    accessibilityRole='button'
                                    onPress={onReconcile}
                                    style={styles.actionIcon}
                                    name={'disconnect'}
                                    type={'antdesign'}
                                />
                            </View>
                        </Tooltip>
                        {affiliate?.consentEnabled && !item.stopped &&
                            <Tooltip
                                label="Opt-Out"
                                openDelay={500}
                                placement="top"
                                hasArrow={true}>
                                <View
                                    style={styles.actionIconContainer}>
                                    <Icon
                                        accessibilityLabel='Opt-Out.'
                                        accessibilityHint='Opt-Out Contact.'
                                        accessibilityRole='button'
                                        onPress={onOptOut}
                                        style={styles.actionIcon}
                                        name={'minuscircleo'}
                                        type={'antdesign'}
                                    />
                                </View>
                            </Tooltip>
                        }
                    </View>
                }
            </TouchableOpacity>
        </>

    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        minHeight: 70,
        borderTopWidth: 1,
        borderTopColor: '#DEDCD9',
        marginBottom: 5,
    },
    firstNameContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden'
    },
    logo: {
        marginRight: 10,
        height: 40,
        width: 40,
        borderRadius: 20
    },
    firstNameText: {
        fontFamily: AppFonts.Roboto_Regular,
        fontSize: 17,
        color: AppColor.Dark,
    },
    column: {
        justifyContent: 'center'
    },
    statusColumn: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 5,
        flexDirection: 'row'
    },
    actionsColumn: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    actionIconContainer: {
        justifyContent: 'center',
        marginHorizontal: 5
    },
    actionIcon: {
        fontSize: 27,
        color: AppColor.Dark
    },
    columnText: {
        fontFamily: AppFonts.Roboto_Regular,
        fontSize: 17,
        color: AppColor.Dark,
    }



})

export default Contact;