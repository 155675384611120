import Alert from 'Alert';
import Modal from 'modal-react-native-web';
import { Menu, useToast } from 'native-base';
import React from 'react';
import {
  Linking,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { CheckBox, Icon } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';
import getData from '../../AsyncUtils/GetData';
import storeData from '../../AsyncUtils/StoreData';
import ListHeader from '../../Components/AlphabetList/ListHeader';
import CustomButton from '../../Components/Button';
import Contact from '../../Components/Contact';
import Loader from '../../Components/Loader';
import Search from '../../Components/Search';
import End_Points from '../../Constants/Api';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import RequestMaker from '../../Middleware/ApiCaller';
import useLogEvent from '../../Middleware/useLogEvent';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';
import useUndo from '../../Middleware/useUndo';
import { loadAllBlockedContactsThunk, loadAllContactsThunk } from '../../Redux/contacts';
import { loadAllDistributionsThunk } from '../../Redux/distributions';
import { loadDuplicateNumbersThunk } from '../../Redux/duplicate_members';
import { RenderPhoneModal } from '../Chat/RenderPhoneModal';
import CSVImportModal from './CSVImport';
import DistributionListView from './DistributionListView';
import { Contacts as DuplicateContacts } from './DuplicateContacts';
import { callContact, deleteItem, loadMemberNumbers, loadReconcileMember, optOutMember, reconcileMember } from './helpers';
import { OptOutModal, ReconcileModal, RemovePhoneModal } from './modals';
import NewAlphabetList from './NewAlphabetList';

const Contacts = ({ navigation, route }) => {
  if(route.params?.type === 'Duplicate') return <DuplicateContacts navigation={navigation} route={route} />
  const Toast = useToast();
  const contactData = useSelector((state) => state.contacts).data;
  const distributionListData = useSelector((state) => state.distributions).data;
  const duplicateContactData = useSelector((state) => state.duplicate_members).messagingPhone;
  const blockedContactData = useSelector((state) => state.contacts).blocked_contacts;
  const [search, setSearch] = React.useState('');

  const contactFilter = (x) =>
  (`${x?.fname?.toLowerCase()} ${x?.lname?.toLowerCase()}`)?.includes(search?.trim()?.toLowerCase())


  const [allContactData, setAllContactData] = React.useState([]);
  const [phoneModalVisible, setPhoneModalVisible] = React.useState(false);
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [optOutModalVisible, setOptOutModalVisible] = React.useState(false);
  const [selectedNumber, setSelectedNumber] = React.useState('');
  const [reconcileVisible, setReconcileVisible] = React.useState(false);
  const [reconcileMembers, setReconcileMembers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sortModalVisible, setSortModalVisible] = React.useState(false);
  const [sortBy, setSortBy] = React.useState('fname');
  const [isCSVVisible, setIsCSVVisible] = React.useState(false);
  const undo = useUndo();
  const logEvent = useLogEvent();
  const {isWebCompact} = useResponsiveLayout();

  let duplicateContacts = [];
  Object.keys(duplicateContactData).forEach(e => {
    duplicateContacts = [...duplicateContacts, ...duplicateContactData[e]]
  })

  const dispatch = useDispatch();
  if (route?.params?.type === 'Blocked') dispatch(loadAllBlockedContactsThunk());

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      dispatch(loadAllContactsThunk());

      await getData('sortBy')
        .then(result => {
          if (!result) return;
          if(result === 'email' || result === 'actions' || result === 'phone') {
            setSortBy('fname');
            return;
          }
          setSortBy(result);
        })
    });

    return unsubscribe;
  }, [navigation])

  React.useEffect(() => {
    const clear = navigation.addListener('blur', () => {
      navigation.setParams({ filter: null, item: null, no_phone: null })
    });

    return clear;
  }, [navigation])


  React.useEffect(() => {
    setSearch('')
    if (route.params?.no_phone) {
      setAllContactData([
        ...contactData.filter((x) => x.phoneNumbers.length == 0),
      ]);
    } else {
      setAllContactData(contactData);
    }
    if (route?.params?.filter) {
      filterContacts();
    }
  }, [route, contactData]);

  const handleCall = async (phoneNumber) => {
    callContact(phoneNumber)
      .then(res => {
        if (res?.data?.success) {
          logEvent('call_placed');
          
          Toast.show({
            duration: 2000,
            title: 'Call initiated, Use your phone to complete the call',
            status: 'success',
          });
          setTimeout(() => {
            Linking.openURL(`tel:${phoneNumber}`);
          }, 2000);
        } else {
          Alert.alert('Fail to Send Message', res?.data?.failures?.join(','));
        }
      })
  };

  const handleLoadMemberNumbers = async (id) => {
    setIsLoading(true);
    loadMemberNumbers(id)
      .then(res => {
        if (res?.data?.success) setPhoneNumbers(res?.data?.phonenumbers)
        setIsLoading(false);
      })
  };

  const handleRecreateContact = async () => {

    const body = {
      "fname": selectedNumber.fname,
      "mname": selectedNumber.mname,
      "lname": selectedNumber.lname,
      "address": selectedNumber.address,
      "city": selectedNumber.city,
      "state": selectedNumber.state,
      "zip": selectedNumber.zip,
      "email_address": selectedNumber.email_address,
      "birth_date": selectedNumber.birth_date,
      "spam": selectedNumber.spam,
      "distributionLists": selectedNumber.distributionLists,
      "phoneNumbers": selectedNumber.phoneNumbers.map(phone => ({
        "countryCode": phone.countryCode,
        "isoCode": phone.isoCode,
        "longCode": phone.longCode,
        "messaging": phone.messaging,
        "type": phone.type,
        "typeId": phone.typeId
      })),
      "source": selectedNumber.source,
      "sourceId": selectedNumber.sourceId,
      "comments": selectedNumber.comments,
      "email_newsletter": selectedNumber.email_newsletter,
      "list_in_directory": selectedNumber.list_in_directory,
      "notify": false,
      "action": "create_member"
    };
    setIsLoading(true);
    const result = await RequestMaker(body);
    if (result.data.success) {
      dispatch(loadAllContactsThunk());
    }
    setIsLoading(false);
  }

  const handleRemove = async () => {
    setRemoveModal(false);
    setIsLoading(true);
    deleteItem(selectedNumber.id)
      .then(res => {
        if (res?.data?.success) {
          undo(handleRecreateContact)
          dispatch(loadAllContactsThunk());
          if (route?.params?.type === 'Duplicate') dispatch(loadDuplicateNumbersThunk());
        } else {
          Toast.show({
            duration: 2000,
            title: res?.data?.errorMessage || 'Something went wrong',
            status: 'error',
          });
        }
        setIsLoading(false);
      })
  };

  const handleOptOut = async () => {
    setOptOutModalVisible(false);
    setIsLoading(true);
    const data = await optOutMember(selectedNumber.id);
    if (data.success) {
      dispatch(loadAllContactsThunk());
      Toast.show({
        duration: 2000,
        title: 'Contact updated successfully.',
        status: 'success',
      });
    } else {
      Toast.show({
        duration: 2000,
        title: data.errorMessage,
        status: 'error',
      });
    }
    setIsLoading(false);
  }

  const handleLoadReconcileMember = async (id) => {
    setIsLoading(true);
    loadReconcileMember(id)
      .then(res => {
        if (res?.data?.success) {
          setReconcileMembers(res.data.contacts);
        }
        setIsLoading(false);
      })
  };


  const handleReconcile = async (contact) => {
    setReconcileVisible(false);
    setIsLoading(true);
    reconcileMember(contact)
      .then(res => {
        setIsLoading(false);
        if (res?.data?.success) {
          dispatch(loadAllContactsThunk());
          Toast.show({ duration: 2000, title: res?.data?.message, status: 'success' });
        }
        else {
          Toast.show({
            duration: 3000,
            type: 'danger',
            text: res?.data?.errorMessage || 'Something went wrong, please try again later.',
          });
        }
      })
  };

  const filterContacts = () => {
    let result = [];

    allContactData.forEach(e => {
      let hasNoMessagingNumber = false;

      e.phoneNumbers.forEach(ee => {
        if (ee.messaging) hasNoMessagingNumber = true;
      })

      if (!hasNoMessagingNumber) result.push(e);
    })

    setAllContactData(result)

  }

  const handleSort = sortBy => {
    storeData('sortBy', sortBy);
    setSortBy(sortBy);
    setSortModalVisible(false);
  }



  const renderListView = ({ item }) => {
    return (
      <Contact
        item={item}
        onPress={() => navigation.navigate('ContactEdit', { item: item, phoneData: null, dl: null })}
        onCall={() => {
          setPhoneModalVisible(true);
          handleLoadMemberNumbers(item.id);
          }
        }
        onMessage={() => {
          if (!item.phoneNumbers.length) {
            Alert.alert('No messaging number', 'This contact has no messaging number defined. Go to the contact details and specify a number to use for messaging.')
          } else {
            navigation.navigate('Messages', { memberData: item, dl: null, template_message: null })
          }
        }}
        onDelete={() => {
          setRemoveModal(true);
          setSelectedNumber(item);
        }}
        onReconcile={() => {
            setReconcileMembers([]);
            setReconcileVisible(true);
            handleLoadReconcileMember(item.id);
        }}
        onOptOut={() => {
          setOptOutModalVisible(true);
          setSelectedNumber(item);
        }}
        columns={['fname', 'lname', 'email', 'phone', 'status', 'actions']}
      />
    );
  }

  const handleSortByProperty = async property => {
    if (sortBy !== property) {
      await storeData('sortBy', property);
      setSortBy(property);
    } else {
      await storeData('sortBy', `${property}-desc`);
      setSortBy(`${property}-desc`)
    }
  }

  const ListHeaderComponent = () => {
    return (
      <ListHeader columns={isWebCompact ? ['fname', 'actions'] : ['fname', 'lname', 'email_address', 'phone', 'status', 'actions']} 
        sortBy={sortBy} 
        handleSortByProperty={val => {
          if(val === 'phone' || val === 'actions') return;
          handleSortByProperty(val)
        }}  
        />
    )
  }

  const handleImportMembers = async (payload) => {
    setIsCSVVisible(false);
    try {
      setIsLoading(true);
      const body = {
        action: 'import_members',
        ...payload,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.status == 200) {
        dispatch(loadAllContactsThunk());
        Toast.show({
          duration: 2000,
          title: 'Imported contacts successfully!',
          status: 'success',
        });
        dispatch(loadAllDistributionsThunk());
        navigation.navigate('AllContacts');
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({
        duration: 2000,
        title: err.message,
        status: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <SafeAreaView
        style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
        <View style={{ flex: 1, paddingLeft: 15, paddingTop: 15, paddingBottom: 15, paddingRight: 5 }}>
          <View style={{ flexDirection: 'row', paddingBottom: 25, alignItems: 'center', justifyContent: 'space-between', marginRight: 10 }}>
            <Text style={{ fontSize: 22 }}>
              {route.params?.type || 'All'} Contacts
            </Text>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Search
                value={search}
                onChangeText={(t) => setSearch(t)}
                placeholder="Filter by name"
                width={'400px'}
                style={{ marginRight: 10 }}
              />

              <CreateContactButton setIsCSVVisible={setIsCSVVisible} navigation={navigation} />

            </View>

          </View>
          {route.params?.type !== 'Distribution Lists' ?
            <NewAlphabetList
              sortBy={sortBy}
              data={route.params?.type === 'Blocked' ? blockedContactData.filter(contactFilter) : allContactData.filter(contactFilter)}
              RenderItem={renderListView}
              ListHeaderComponent={ListHeaderComponent}
            />
            :
            <DistributionListView search={search} navigation={navigation} />
          }
        </View>
        {
          phoneModalVisible &&
          <Modal visible={phoneModalVisible} transparent={true}>
            <RenderPhoneModal
              handleCall={handleCall}
              phoneNumbers={phoneNumbers}
              setPhoneModalVisible={setPhoneModalVisible}
            />
          </Modal>
        }
        {
          removeModal &&
          <Modal visible={removeModal} transparent={true}>
            <RemovePhoneModal
              handleRemove={handleRemove}
              setRemoveModal={setRemoveModal}
            />
          </Modal>
        }
        {
          optOutModalVisible &&
          <Modal visible={optOutModalVisible} transparent={true}>
            <OptOutModal
              handleOptOut={handleOptOut}
              setOptOutModal={setOptOutModalVisible}
            />
          </Modal>
        }
        {
          reconcileVisible &&
          <Modal visible={reconcileVisible} transparent={true}>
            <ReconcileModal
              setReconcileVisible={setReconcileVisible}
              reconcileMembers={reconcileMembers}
              handleReconcile={handleReconcile}
              isLoading={isLoading}
            />
          </Modal>
        }
        {
          sortModalVisible &&
          <SortModal
            visible={sortModalVisible}
            handleSort={handleSort}
            sortBy={sortBy}
          />
        }
        {isCSVVisible &&
          <CSVImportModal
            visible={isCSVVisible}
            setVisible={setIsCSVVisible}
            handleImportMembers={handleImportMembers}
          />
        }

      </SafeAreaView>
      {isLoading && <Loader />}
    </>
  );
};

const SortModal = ({ visible, handleSort, sortBy }) => {
  const [sort, setSortBy] = React.useState(sortBy);

  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0008',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{ width: '90%', backgroundColor: 'white', padding: 20 }}>
          <Text
            style={{
              textAlign: 'center',
              color: AppColor.PrimaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
            }}>
            Sort By
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>

          <View style={{ marginVertical: 10 }}>
            <CheckBox
              checked={sort === 'fname' || sort === 'name'}
              onPress={() => setSortBy('fname')}
              title="First Name"
              containerStyle={{ flex: 1 }}
            />
            <CheckBox
              checked={sort === 'lname'}
              onPress={() =>
                setSortBy('lname')
              }
              title="Last Name"
              containerStyle={{ flex: 1 }}
            />
          </View>
          <CustomButton title="Done" onPress={() => { handleSort(sort) }} />
        </View>
      </View>
    </Modal>
  );
};


const CreateContactButton = props => {
  return (
    <Menu closeOnSelect={true} placement='bottom' w="250" trigger={triggerProps => {
      return (
        <TouchableOpacity onPress={triggerProps.onPress}
          {...triggerProps}>
          <Ionicons
            name='person-add'
            color='black'
            size={26}
          />
        </TouchableOpacity>
      )
    }}
    >
      <Menu.Item
        onPress={() => { props.navigation.navigate('ImportFromCrm') }}
      >
        <TouchableOpacity
          accessibilityRole='button'
          accessibilityLabel='Import Contacts from CRM'
          activeOpacity={1}
          style={{ flexDirection: 'row', alignItems: 'center', pointerEvents: 'none' }}
        >
          <Icon
            color={'black'}
            name={'database-import'}
            type={'material-community'}
          />
          <Text style={{ marginLeft: 5 }}>Import from CRM</Text>
        </TouchableOpacity>
      </Menu.Item>
      <Menu.Item
        onPress={() => props.setIsCSVVisible(true)}
      >

        <TouchableOpacity
          accessibilityRole='button'
          accessibilityLabel='Import Contacts from CSV'
          style={{ flexDirection: 'row', alignItems: 'center', pointerEvents: 'none' }}

          activeOpacity={1}
        >
          <Icon
            color={'black'}
            name={'database-import'}
            type={'material-community'}
          />
          <Text style={{ marginLeft: 5 }}>Import Contacts from CSV</Text>
        </TouchableOpacity>
      </Menu.Item>
      <Menu.Item
        onPress={() => props.navigation.navigate('ContactEdit', { phoneData: null, dl: null, item: null })}
      >

        <TouchableOpacity
          accessibilityRole='button'
          accessibilityLabel='Add contacts manually.'
          activeOpacity={1}
          style={{ flexDirection: 'row', alignItems: 'center', pointerEvents: 'none' }}

        >
          <Icon
            color={'black'}
            name={'adduser'}
            type={'antdesign'}
          />
          <Text style={{ marginLeft: 5 }}>Add contacts manually</Text>
        </TouchableOpacity>
      </Menu.Item>
      <Menu.Item
        onPress={() => props.navigation.navigate('AddDL')}

      >

        <TouchableOpacity
          accessibilityRole='button'
          accessibilityLabel='Add Distribution List manually'
          activeOpacity={1}
          style={{ flexDirection: 'row', alignItems: 'center', pointerEvents: 'none' }}
        >
          <Icon
            color={'black'}
            name={'addfile'}
            type={'antdesign'}
          />
          <Text style={{ marginLeft: 5 }}>Add DL manually</Text>
        </TouchableOpacity>
      </Menu.Item>


    </Menu>
  )
}


export default Contacts;