import React from 'react';
import {View, Text, ScrollView, TouchableOpacity, StyleSheet} from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import Loader from '../../Components/Loader';
import { providers } from '../IntegrationProvider';
import End_Points from '../../Constants/Api';
import CustomButton from '../../Components/Button';

const RenderPhoneModal = (props) => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0005',
      }}>
      <View
        style={{
          maxHeight: 500,
          padding: 15,
          backgroundColor: 'white',
          borderRadius: 20,
          borderColor: AppColor.PrimaryColor,
          borderWidth: 1,
        }}>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 20,
            textAlign: 'center',
            marginTop: 10,
          }}>
          Call Contact
        </Text>
        <View
          style={{
            height: 1,
            backgroundColor: AppColor.PrimaryColor,
            marginVertical: 10,
          }}></View>
        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 15 }}>
          Choose the number you would like to call
        </Text>
        <ScrollView style={{ marginVertical: 10 }}>
          {props.phoneNumbers.map((x) => {
            console.log(x);
            return (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 20,
                    color: AppColor.PrimaryColor,
                  }}>
                  {x.countryCode}
                  {x.longCode}
                  {` (${x.typeLabel})`}
                </Text>
                <MaterialIcons
                  onPress={() => {
                    props.setPhoneModalVisible(false);
                    props.handleCall(x.countryCode + x.longCode);
                  }}
                  name="phone"
                  size={30}
                  color={AppColor.PrimaryColor}
                />
              </View>
            );
          })}
        </ScrollView>
        <TouchableOpacity
          onPress={() => props.setPhoneModalVisible(false)}
          style={{
            padding: 10,
            backgroundColor: AppColor.PrimaryColor,
            borderRadius: 5,
            marginBottom: 5,
          }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.SecondaryColor,
              fontSize: 20,
              textAlign: 'center',
            }}>
            Cancel
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const RemovePhoneModal = (props) => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0005',
      }}>
      <View
        style={{
          padding: 30,
          backgroundColor: 'white',
          borderRadius: 20,
          borderColor: AppColor.PrimaryColor,
          borderWidth: 1,
          maxWidth: 500,
          width: '100%',
        }}>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Bold,
            fontSize: 18,
            textAlign: 'center',
          }}>
          {props.title ? props.title : 'Are you sure you want to delete the contact?'}
        </Text>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 16,
            textAlign: 'center',
            marginTop: 10,
          }}>
          {props.message ? props.message : `The messages with the contact's number will stay in MyRepChat.`}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            gap:20,
            marginTop: 20,
          }}>
            { !props.hideCancel &&
            <CustomButton
            title="Cancel"
            style={{width:'40%'}}
            outline
            onPress={() => props.setRemoveModal(false)}
            />
            }
            <CustomButton
            title={props.continueLabel ? props.continueLabel : "Delete"}
            style={{width:'40%'}}
            onPress={() => props.handleRemove()}
            />
        </View>
      </View>
    </View>
  );
};

const OptOutModal = (props) => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0005',
      }}>
      <View
        style={{
          padding: 30,
          backgroundColor: 'white',
          borderRadius: 20,
          borderColor: AppColor.PrimaryColor,
          borderWidth: 1,
          maxWidth: 500,
          width: '100%',
        }}>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Bold,
            fontSize: 18,
            textAlign: 'center',
          }}>
          {'Are you sure you want to Opt Out the contact?'}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            gap:20,
            marginTop: 20,
          }}>
            <CustomButton
              title="Cancel"
              style={{width:'40%'}}
              outline
              onPress={() => props.setOptOutModal(false)}
            />
            <CustomButton
              title={props.continueLabel ? props.continueLabel : "Confirm"}
              style={{width:'40%'}}
              onPress={() => props.handleOptOut()}
            />
        </View>
      </View>
    </View>
  );
};

const ReconcileModal = (props) => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0005',
      }}>
      <View
        style={{
          height: 500,
          padding: 30,
          backgroundColor: 'white',
          borderRadius: 20,
          borderColor: AppColor.PrimaryColor,
          borderWidth: 1,
          minWidth: 500,
        }}>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 20,
            textAlign: 'center',
            marginTop: 10,
          }}>
          Reconcile Contact
        </Text>
        {props.reconcileMembers.length === 0 && (
          <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 15 }}>
            {props.isLoading ? 'Connecting...' : 'No matching CRM contacts'}
          </Text>
        )}

        <ScrollView style={{ marginVertical: 10 }}>
          {props.reconcileMembers.length > 0 &&
            props.reconcileMembers.map((x) => {

              let image = require('../../Assets/Images/splashlogo.png');
              let provider = Object.values(providers).find((i) => i.name == x.source);
              if (provider) {
                image = provider.smallImageUrlPath;
              }
    
              return (
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginVertical: 5,
                    alignItems:'center',
                  }}
                  onPress={() => props.handleReconcile(x)}>
                    <img src={image} width={40} height={40} />
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Regular,
                      fontSize: 20,
                      color: AppColor.PrimaryColor,
                    }}>
                    {x.name}
                    {x.email_address && `(${x.email_address})`}
                  </Text>
                  <View>
                  {x.phoneNumbers.map((v) => (
                    <Text
                      style={{
                        fontFamily: AppFonts.Roboto_Regular,
                        fontSize: 20,
                        color: AppColor.PrimaryColor,
                      }}>
                      {v.countryCode}
                      {v.longCode}
                      {` (${v.typeLabel})`}

                    </Text>
                  ))}
                  </View>
                </TouchableOpacity>
              );
            })}
        </ScrollView>
        <TouchableOpacity
          onPress={() => props.setReconcileVisible(false)}
          style={{
            padding: 5,
            backgroundColor: AppColor.PrimaryColor,
            borderRadius: 5,
            marginBottom: 5,
          }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.SecondaryColor,
              fontSize: 20,
              textAlign: 'center',
            }}>
            Cancel
          </Text>
        </TouchableOpacity>
        {props.isLoading && <Loader backgroundColor="transparent" />}
      </View>
    </View>
  );
};


export {RenderPhoneModal, RemovePhoneModal, ReconcileModal, OptOutModal }