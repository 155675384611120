import React from 'react';
import { Text, View } from 'react-native';
import { Linking } from 'react-native';
import CustomButton from '../../../../Components/Button';
import Loader from '../../../../Components/Loader';
import AppFont from '../../../../Constants/Fonts';
import AppColor from '../../../../Constants/Color';
import IntegrationComponent from './IntegrationComponent';
import { CheckBox, Input } from 'react-native-elements';
import { Alert } from 'react-native';

import { setAttributes } from '../../../../Redux/integrationData';

import End_Points from '../../../../Constants/Api';
import RequestMaker from '../../../../Middleware/ApiCaller';
import getData from '../../../../AsyncUtils/GetData';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useToast } from 'native-base';


export default function SalesforceComponent({
  provider,
  integration,
  defaultDomain,
  sampleDomain,
  groupIntegration,
  createIntegration,
  updateIntegration,
}) {
  const isLocked = groupIntegration == null ? false : groupIntegration.locked;
  const [loading, setLoading] = React.useState(false);
  const [integ, setIntegration] = React.useState(null);
  const [includeDueDate, setIncludeDueDate] = React.useState(false);
  const [includeAttachments, setIncludeAttachments] = React.useState(false);
  const dispatch = useDispatch();
  const group = useSelector((state) => state.User)?.profile?.group;
  const [config, setConfig] = React.useState({})
  const defaultDomains = integration?.instance_url ? integration.instance_url : (config?.host ? config.host : defaultDomain);
  const [domain, setDomain] = React.useState();
  const toast = useToast();

  const handleConnect = async () => {
    if (domain == null || domain.trim() == '') {
      Alert.alert(
        'Error',
        'You must enter your ' + provider.label + ' domain.'
      );
      return;
    }
    if(!integration){
      await createIntegration();
      getOauth();
    }
    else if(integration && integration.enabled){
      getOauth();
    } else {
      toast.show({title:'You must enable, and then save your integration before connecting.', status: 'error'});
      return;
    }
    console.log('After Calling oauth');
  };

  const getOauth = async () => {
    const body = {
      action: 'get_oauth_connect',
      provider: provider.name,
      page: 'index.html'
    };
    try {
      setLoading(true);

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
    	Linking.canOpenURL(result.data.url).then((supported) => {
      		if (supported) {
				console.log("Opening link",result.data.url)
        		Linking.openURL(result.data.url);
      		} else {
        		console.log("Don't know how to open URI: ");
      		}
    	});
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };


const checkGroupSettings = async () => {
  const body = {
    action: 'get_integrations',
    provider: 'salesforce',
    groupintegration: group?.id
  };
  
  let device = await getData('@device_id');
  const headers = { 'x-device': device };
  let Config = {
    method: 'post',
    url: End_Points.Base_url + End_Points.End,
    data: body,
    headers: headers,
    redirect: 'follow',
  };
  let result = await RequestMaker(Config);
  if (result.data.success) {
    const config = {
      locationTask: result.data?.integrations[0]?.locationTask,
      locationCall: result.data?.integrations[0]?.locationCall,
      locationNotes: result.data?.integrations[0]?.locationNotes,
      locationEmail: result.data?.integrations[0]?.locationEmail,

      messagesDaily: result.data?.integrations[0]?.messagesDaily,
      messagesNever: result.data?.integrations[0]?.messagesNever,
      messagesRealtime: result.data?.integrations[0]?.messagesRealtime,

      host: result.data?.integrations[0]?.host
    }
    setConfig(config)

    

  }
}  

  React.useEffect(() => {
    setIntegration(integration);
    setIncludeDueDate(
      integration?.includeDueDate === "true"
    );
    setIncludeAttachments(
      integration?.includeAttachments ===  "true");
    
    
  }, [integration]);

  React.useEffect(()=>{
    let domain = (integration?.host ? integration.host : defaultDomain);
    if(String(domain).includes('https://')){
      domain = String(domain).replace('https://', '');
    }
    setDomain(defaultDomains)
  },[integration,config])
  
  React.useEffect(() => {
    dispatch(
      setAttributes({
        host: domain,
        includeAttachments: includeAttachments,
        includeDueDate: includeDueDate,
      })
    );
  }, [domain, includeDueDate, includeAttachments]);
  
  React.useEffect(()=>{
    checkGroupSettings()
  },[])

  return (
    <View style={{ marginHorizontal: 15 }}>
      <Input
        label={'Custom Domain'}
        value={domain}
        onChangeText={(t) => setDomain(t)}
        placeholder={sampleDomain}
        labelStyle={{ color: AppColor.PrimaryColor }}
        errorStyle={{ height: 0 }}
        inputStyle={{ fontSize: 15 }}
        containerStyle={{ flex: 1 }}
        inputContainerStyle={{ borderColor: AppColor.PrimaryColor }}
      />
      <CustomButton
        width={'30%'}
        style={{alignSelf:'flex-end',marginVertical:10}}
        title={`Connect to ${provider.label}`}
        onPress={handleConnect}
      />
        <View style={{ borderWidth: 1, padding: 10, marginTop: 10 }}>
          <CheckBox
            title={'Include Due Date in Activities'}
            checked={includeDueDate}
            onPress={() => setIncludeDueDate(!includeDueDate)}
          />
          <CheckBox
            title={'Include MMS as Attachments'}
            checked={includeAttachments}
            onPress={() => setIncludeAttachments(!includeAttachments)}
          />
        </View>
        <IntegrationComponent
          config={config}
          provider={provider}
          integration={integration}
          allowContactEdits={true}
          allowLocation={true}
        />
      {loading && <Loader />}
   </View>
  );
}
